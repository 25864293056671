<template>
  <div class="m1">
    <div class="hd ">
      <div class="top-button">
        <el-button type="primary" @click="addN()">添加管理员</el-button>
      </div>
    </div>

    <!-- 列表 -->
    <div class="lb">
      <div class="table-box">
        <el-table :data="accountList" style="width: 100%" border>

          <el-table-column prop="name" label="姓名" width="200">
          </el-table-column>
          <el-table-column prop="account" label="密码">
          </el-table-column>
          <el-table-column prop="KG" label="用户状态">
            <template slot-scope="scope">
              <el-switch @change="zt(scope.row)" v-model="scope.row.KG" active-color="#5daf34" inactive-color="#f8cc82"
                active-text="启用" inactive-text="禁用">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" flex="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="edmm(scope.row)">修改密码</el-button>
              <el-button size="mini" type="danger" @click="del(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 翻页 -->
    <div style="width: 100%; height: 5rem; padding: 1rem 0; margin-top: 4rem;">
      <el-pagination style="float: right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="queryFrom.page" :page-sizes="[10, 20]" :page-size="10"
        layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog title="操作" :visible.sync="dialogFormVisible" width="30%">
      <el-form :model="form">
        <el-form-item v-if="ed" label="姓名:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="ed" label="账号(手机号):" :label-width="formLabelWidth">
          <el-input v-model="form.account" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码:" :label-width="formLabelWidth">
          <el-input v-model="form.password" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button v-if="ed" type="primary" @click="Form()">确 定</el-button>
        <el-button v-if="!ed" type="primary" @click="Form1()">修 改</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'gsgly',
  data() {
    return {
      value: true,
      queryFrom: {
        keyword: "",
        page: 1,
        size: 10
      },
      accountList: [],
      currentPage: 1,
      total: null,
      form: {
        name: '',
        account: '',
        password: '',
        projectId:"1",
      },
      formLabelWidth: '6rem',
      ed: true,
      dialogFormVisible: false,
      Xarr: [],
      value: ''
    }
  },
  created() {
    this.getAccountList()
  },
  methods: {
    khgl(id) {
      this.$router.push('/glkh?id=' + id)
    },
    Xz(e) {

      this.form.projectId = e
    },
    edmm(item) {
      this.form = {
        "id": item.id,
        "password": ''
      }
      this.ed = false
      this.dialogFormVisible = true
    },
    addN() {
      let pId = this.form.projectId;
      this.form = {
        name: '',
        account: '',
        password: '',
        projectId: pId
      }
      this.ed = true
      this.dialogFormVisible = true
    },
    Form() {
      console.log(this.form);
      this.$ajax('addkh', 'post', this.form).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.getAccountList()
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    Form1() {
      this.$ajax('edkh', 'post', this.form).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.getAccountList()
          this.dialogFormVisible = false
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    zt(item) {
      if (item.KG) {
        item.state = 0
      } else {
        item.state = 1
      }
      let obj = {
        "id": item.id,
        "state": item.state
      }
      console.log(obj);
      this.$ajax('ztkh', 'post', obj).then(res => {
        console.log(res);
        if (res.code == 2000) {
          this.getAccountList()
          this.$message({
            type: 'success',
            message: res.message
          });
        } else {
          this.$message({
            type: 'error',
            message: res.message
          });
        }
      })
    },
    xian(item) {
      item.qr = !item.qr
    },
    del(id) {
      this.$confirm('是否删除此账号?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        showClose: false
      }).then(() => {
        this.$ajax('delkhgl', 'delete', id).then(res => {
          this.getAccountList()
          this.$message({
            type: 'success',
            message: '删除成功'
          });
        })
      }).catch(() => {

        this.$message({
          type: 'info',
          message: '取消删除'
        });
      });

    },
    handleSizeChange(val) {
      this.queryFrom.size = val
      this.getAccountList()
    },
    handleCurrentChange(val) {
      this.queryFrom.page = val
      this.getAccountList()
    },
    getAccountList() {
      this.$ajax('khgl', 'post', this.queryFrom).then(res => {
        this.accountList = res.data.records
        this.total = res.data.total
        this.accountList.forEach(item => {
          if (item.state == 0) {
            this.$set(item, 'KG', true)
          } else {
            this.$set(item, 'KG', false)
          }

        })
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.bg {
  background-color: rgba(43, 201, 222, 0.6);
}

.bg1 {
  background-color: rgba(162, 162, 162, 0.2);
}

.bg2 {
  background: rgba(14, 242, 242, 0.3);
}

.m1 {
  display: flex;
  width: 100%;
  color: #FFFFFF;
  flex-wrap: wrap;

  .hd {
    display: flex;
    width: 100%;
    height: 5rem;
    flex-direction: column-reverse;

    .top-button {
      display: flex;
      width: 10rem;
      height: 3rem;
      align-items: center;
      justify-content: center;
    }
  }

  .lb {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    justify-content: center;

    .table-box {
      width: 98%;

    }
  }

  .qx {
    position: absolute;
    top: .1rem;
    left: 50rem;
    display: inline-block;

    .lst {
      display: inline-block;
      width: 6.6rem;
      height: 2.6rem;
      line-height: 2.6rem;
      background-color: rgba(43, 201, 222, 0.6);
      text-align: center;
      border-radius: .3rem;
      margin-right: 1rem;
    }
  }

  .xg {
    position: absolute;
    top: .5rem;
    right: 2rem;
    width: 27rem;
    height: 2.6rem;
    line-height: 2.6rem;

    .gg {
      cursor: pointer;
      display: inline-block;
      width: 6.6rem;
      height: 2.6rem;
      line-height: 2.6rem;
      text-align: center;
      background-color: rgba(28, 184, 184, 1);
      border-radius: .4rem;
      margin-left: 1rem;
    }
  }
}

.main {
  position: relative;
  width: 100%;
  // height: 4.2rem;
  // margin-top: 1rem;
  padding: .5rem 0;


  .d1:hover {
    background-color: rgba(117, 117, 117, 0.4);
  }

  .m_1 {
    display: inline-block;
    width: 5%;
    height: 4.2rem;
    line-height: 4.2rem;
    text-align: center;
    margin-right: .5%;
    // border: 1px solid rgba(43, 201, 222, 0.6);
    box-sizing: border-box;
  }

  .m_2 {
    display: inline-block;
    width: 94.5%;
    height: 4.2rem;
    line-height: 4.2rem;
    // border: 1px solid rgba(43, 201, 222, 0.6);
    box-sizing: border-box;
  }

  .m_3 {
    position: relative;
    box-sizing: border-box;
    float: right;
    width: 94.5%;
    height: 4.2rem;
    // border: 1px solid rgba(43, 201, 222, 0.6);
    box-sizing: border-box;
    margin-top: .5rem;
    line-height: 4.2rem;
    z-index: 999;

    .zh {
      margin-left: 1rem;
      display: inline-block;
      width: 16.8rem;
      overflow: hidden;
    }

    .qx {
      position: absolute;
      top: .1rem;
      left: 50rem;
      display: inline-block;

      .lst {
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        background-color: rgba(43, 201, 222, 0.6);
        text-align: center;
        border-radius: .3rem;
        margin-right: 1rem;
      }
    }

    .xg {
      position: absolute;
      top: .5rem;
      right: 2rem;
      width: 27rem;
      height: 2.6rem;
      line-height: 2.6rem;

      .gg {
        display: inline-block;
        width: 6.6rem;
        height: 2.6rem;
        line-height: 2.6rem;
        text-align: center;
        background-color: rgba(28, 184, 184, 1);
        border-radius: .4rem;
        margin-left: 1rem;
      }
    }
  }

  .m_3:last-child {
    margin-bottom: 1rem;
  }
}
</style>
